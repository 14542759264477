.homePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 66px;
  font-weight: bolder;
}

.pageTitle {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bolder;
  font-size: 18px;
}
